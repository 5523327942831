import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta/parametros`;
const urlBase = `${api}`;

const formDataType = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export default {
    obterParametros() {
        return axiosJwt.get(`${urlBase}`);
    },

    atualizar(parametros) {
        return axiosJwt.post(`${urlBase}/atualizar`, parametros);
    },

    obterUnidades() {
        return axiosJwt.get(`${urlBase}/unidades`);
    },

    obterProjetos(codUnidade) {
        return axiosJwt.get(`${urlBase}/unidades/${codUnidade}/projetos`);
    },

    obterAcoes(codUnidade, codProjeto) {
        return axiosJwt.get(`${urlBase}/unidades/${codUnidade}/projetos/${codProjeto}/acoes`);
    },

    obterDocumento(documento) {
        return axiosJwt.get(`${urlBase}/movimentos/${documento.codMov}/download/${documento.tipo.toLowerCase()}`,{responseType: 'blob'})
            .then(response => {
                return response.data
            });
    },

    inserirDocumento(documento) {
        const formData = new FormData()
        formData.append('codMov', documento.codMov)
        formData.append('tipo', documento.tipo)
        if(documento.arquivo){
            formData.append('arquivo', documento.arquivo)
        }
        return axiosJwt.post(`${urlBase}/movimentos/${documento.codMov}/${documento.tipo.toLowerCase()}/upload`, formData, formDataType)
            .then(response => {
                return response.data
            });
    },
};
