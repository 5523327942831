<template>
    <painel titulo="Convênios de Execução Direta - Parâmetros - Atualizar" icone="pi pi-cog" :refreshFunction="obterParametros">
        <div class="mb-4">
            <erros-box :erros="erros"></erros-box>
            <div class="formgrid grid p-fluid">
                <div class="field col-12 md:col-4">
                    <label class="required">Cód. Movimento</label>
                    <InputText type="text" v-model="codMov" />
                </div>
                <div class="field col-12 md:col-4">
                    <label class="required">Unidade</label>
                    <Dropdown :options="unidades" v-model="codUnidade" optionLabel="nome" optionValue="codUnidade" @input="$v.codUnidade.$touch()"> ></Dropdown>
                    <small class="p-error" v-if="v$.codUnidade.$error">O campo Unidade é obrigatório</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label class="required">Projeto</label>
                    <Dropdown :options="projetos" v-model="codProjeto" optionLabel="nome" optionValue="codProjeto" @input="$v.codProjeto.$touch()"> </Dropdown>
                    <small class="p-error" v-if="v$.codProjeto.$error">O campo Projeto é obrigatório</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label class="required">Ação</label>
                    <Dropdown :options="acoes" v-model="codAcao" optionLabel="nome" optionValue="codAcao" @input="$v.codAcao.$touch()"></Dropdown>
                    <small class="p-error" v-if="v$.codAcao.$error">O campo Ação é obrigatório</small>
                </div>
            </div>
        </div>
        <btn-voltar :history="true"></btn-voltar>
        <Button class="ml-2" label="Salvar" icon="pi pi-save" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import Services from './services';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        parametros: {
            type: Object,
        },

        tipo: {
            type: String,
            default: () => 'I',
        },
    },

    emits: ['obterParametrosAtualizados'],

    data() {
        return {
            erros: [],
            codMov: null,
            codUnidade: null,
            nomeUnidade: null,
            codProjeto: null,
            nomeProjeto: null,
            codAcao: null,
            nomeAcao: null,
            unidades: [],
            projetos: [],
            acoes: [],
        };
    },

    validations() {
        return {
            codMov: { required },
            codUnidade: { required },
            codProjeto: { required },
            codAcao: { required },
        };
    },

    methods: {
        obterParametros() {
            this.$store.dispatch('addRequest');
            Services.obterParametros().then((response) => {
                if (response?.success) {
                    this.preencherFormulario(response.data);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'ConveniosDeExecucaoDireta',
                        detail: 'Erro ao obter os parâmetros',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterUnidades() {
            this.$store.dispatch('addRequest');
            Services.obterUnidades().then((response) => {
                if (response) {
                    if (response.success) {
                        this.unidades = response.data;
                    } else {
                        this.$toast.error(response.errors, 'ERROR', this.$toastConfig.error);
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterProjetos() {
            this.$store.dispatch('addRequest');
            Services.obterProjetos(this.codUnidade).then((response) => {
                if (response) {
                    if (response.success) {
                        this.projetos = response.data;
                    } else {
                        this.$toast.error(response.errors, 'ERROR', this.$toastConfig.error);
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterAcoes() {
            this.$store.dispatch('addRequest');
            Services.obterAcoes(this.codUnidade, this.codProjeto).then((response) => {
                if (response) {
                    if (response.success) {
                        this.acoes = response.data;
                    } else {
                        this.$toast.error(response.errors, 'ERROR', this.$toastConfig.error);
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },

        preencherFormulario(parametros) {
            this.codMov = parametros.codMov;
            this.codUnidade = parametros.codUnidade;
            this.nomeUnidade = parametros.nomeUnidade;
            this.codProjeto = parametros.codProjeto;
            this.nomeProjeto = parametros.nomeProjeto;
            this.codAcao = parametros.codAcao;
            this.nomeAcao = parametros.nomeAcao;
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja salvar os parâmetros?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$store.dispatch('addRequest');
            let parametrosDto = {
                codMov: this.codMov,
                codUnidade: this.codUnidade,
                codProjeto: this.codProjeto,
                codAcao: this.codAcao,
                nomeUnidade: this.nomeUnidade,
                nomeProjeto: this.nomeProjeto,
                nomeAcao: this.nomeAcao,
            };
            Services.atualizar(parametrosDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'ConveniosDeExecucaoDireta',
                        detail: 'Parâmetros atualizados com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar();
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar() {
            this.$emit('obterParametrosAtualizados');
            this.$router.push({
                name: 'Convenios_Parametros',
            });
        },
    },

    watch: {
        codUnidade(newVal) {
            if (newVal !== null) {
                this.nomeUnidade = this.unidades.find((p) => p.codUnidade === this.codUnidade)?.nome;
                this.obterProjetos();
            }
        },

        codProjeto(newVal) {
            if (newVal !== null) {
                this.nomeProjeto = this.projetos.find((p) => p.codProjeto === this.codProjeto)?.nome;
                this.obterAcoes();
            }
        },

        codAcao(newVal) {
            if (newVal !== null) {
                this.nomeAcao = this.acoes.find((p) => p.codAcao === this.codAcao)?.nome;
            }
        },
    },

    mounted() {
        if (this.parametros) {
            this.preencherFormulario(this.parametros);
            this.obterUnidades();
        }
    },
};
</script>
